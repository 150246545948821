import styled from "styled-components"

export const _avatar = styled.img`
    width: auto;
    height: auto;
    object-fit: contain;
    max-width: 100%;
`

export const _circleAvatar = styled.img`
    object-fit: contain;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
`

export const _signatureText = styled.div`
    flex: 1;
`;

export const _userName = styled.figure`
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--dark);
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-end: 0em;
`

export const _userTextPrimary = styled.figure`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--slate-grey);
    margin-inline-start: 0;
    margin-inline-end: 0;
    margin-block-start: 0em;
    margin-bottom: 0;
`

export const _userTextSecondary = styled(_userTextPrimary)`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 0.09px;
    color: ${props => props.color || 'var(--slate-grey)'}
`

export interface SignatureProps {
    name: string
    title: string
    image?: string
    company: string
    isMobile: boolean
    className?: string
}
