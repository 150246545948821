import * as React from "react"

import { Row } from "react-flexbox-grid"
import styled from "styled-components"
import { _circleAvatar, _userName, _userTextSecondary, _signatureText, SignatureProps } from "./shared"

const _signature = styled.div`
    margin: 1em 0 2em;
    display: flex;
    align-items: center;
`

const _name = styled(_userName)`
    margin-top: 0;
`

export const HorizontalSignature: React.FC<SignatureProps> = ({ name, title, company, image }) => (
    <Row middle="xs">
        <_signature>
            <_circleAvatar src={image} />
            <_signatureText>
                <_name>{name}</_name>
                <_userTextSecondary color='var(--dark)'>{title}</_userTextSecondary>
                <_userTextSecondary color='var(--dark)'>{company}</_userTextSecondary>
            </_signatureText>
        </_signature>
    </Row>
)
