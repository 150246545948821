import styled from "styled-components"
import { _Section } from "../components/shared"

export const _SectionWithBorder = styled(_Section)`
    border-top: solid 1px #efedfc;
    padding-top: 1.5em;
`

export const _ContentWrapper = styled.div`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    padding-bottom: 2em;

    p.block-img {
        text-align: center;
    }

    img {
        max-width: 100%;
        margin: auto;
    }

    a {
        color: #4340de;

        * {
            color: inherit;
        }
    }
`
