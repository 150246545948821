import * as React from "react"

import styled from "styled-components"

interface PreviousWebinarProps {
    webinar_title: string
    link_to_webinar: {
        url: string
    }
    webinar_cover_photo: any
}

const _linkWrapper = styled.a`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-top: 18px;
    
    &:hover {
        text-decoration: none;
        border-bottom: none;

        strong {
            text-decoration: underline;
        }
    }
`;

const _webinarCover = styled.img`
    display: inline-block;
    width: 100%;
    border-radius: 10px;
`;

const _webinarTitle = styled.strong`
    font-family: Nunito;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: var(--dark);
    margin-top: 1.06em;
`;

export const PreviousWebinar: React.FC<PreviousWebinarProps> = ({ webinar_title, link_to_webinar, webinar_cover_photo }) => {

    return (
        <_linkWrapper href={link_to_webinar.url}>
            {webinar_cover_photo && <_webinarCover src={webinar_cover_photo.url} alt={webinar_cover_photo.alt} />}
            <_webinarTitle>{webinar_title}</_webinarTitle>
        </_linkWrapper>
    );
}
