import * as React from "react"
import { _Section } from "../shared"
import { _processLink } from "../shared/links.styles"
import { _h1, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { prismicGetText, prismicGetRichText } from "../../utils/prismicHelper"
import { _externalLinkButton } from "../shared/buttons/"
import { _customAlignCol } from "../shared/"
import { dateDecorator } from "../../utils"
import { _SectionWithBorder, _ContentWrapper } from "../../templates/webinar.styles"
import { AppStateContext } from "../../utils/PageWrapper"

import { Hero } from "../../components/productsPage/shared"
import { Col, Row } from "react-flexbox-grid"
import { HorizontalSignature } from "./"
import { PreviousWebinar } from "./PreviousWebinar"

const buildModeratedByInfo = ({ company, position, name }: { company: string, position: string, name: string }): string => (
    `Moderated by ${company} ${position}, ${name}`
)

export const desktopContent = (doc: any, heroRef: React.MutableRefObject<null>) => (
    <Row>
        <Col lg={7}>
            <_Section marginBottom={2.5}>
                <Hero
                    description="Radicle Webinar Series"
                    title={prismicGetText(doc.title_webinar)}
                    lead={prismicGetText(doc.subtitle_webinar)}
                    leftSideLgWidth={12}
                    headerFontSize={40}>
                    <Col sm={12}>
                        <Row middle="xs">
                            <Col lg={6} md={12} sm={12}>
                                <_description fontSize={14}>
                                    {
                                        buildModeratedByInfo({
                                            company: prismicGetText(doc.moderator_company),
                                            position: prismicGetText(doc.moderator_position),
                                            name: prismicGetText(doc.moderated_by),
                                        })
                                    }
                                </_description>
                                <_description fontSize={14} fontWeight="bold">
                                    {dateDecorator(doc.webinar_date)} at {prismicGetText(doc.webinar_hour)}
                                </_description>
                            </Col>

                            <_customAlignCol align="right" lg={6} md={12} sm={12}>
                                <_externalLinkButton ref={heroRef} href={doc.cta_link_to_registration.url}>
                                    {prismicGetText(doc.cta_label_webinar)}
                                </_externalLinkButton>
                            </_customAlignCol>
                        </Row>
                    </Col>
                </Hero>
            </_Section>

            <_SectionWithBorder marginTop={2.5} marginBottom={1}>
                <_ContentWrapper>{prismicGetRichText(doc.description_webinar)}</_ContentWrapper>
            </_SectionWithBorder>
        </Col>

        <Col lg={4} lgOffset={1}>
            <_Section>
                <_description align="left" fontWeight="bold">Speakers</_description>
                {doc.speakers.map((speaker: any, index: number) => {
                    return (
                        <HorizontalSignature
                            key={index}
                            image={speaker.image_speaker.url}
                            name={prismicGetText(speaker.speaker_name)}
                            title={prismicGetText(speaker.speaker_position)}
                            company={prismicGetText(speaker.speaker_company_name)}
                            isMobile={false}
                        />
                    )
                })}
                <_description align="left" fontWeight="bold">Moderator</_description>

                <HorizontalSignature
                    image={doc.moderator_picture.url}
                    name={prismicGetText(doc.moderated_by)}
                    title={prismicGetText(doc.moderator_position)}
                    company={prismicGetText(doc.moderator_company)}
                    isMobile={false}
                />

                {doc.previous_webinars && doc.previous_webinars.length && <_description align="left" fontWeight="bold">Previous webinars</_description>}
                {doc.previous_webinars && doc.previous_webinars.length && doc.previous_webinars.map((prevWebinar: any, i: number) => {
                    return (
                        <PreviousWebinar
                            key={`${i}.${prevWebinar.webinar_title}`}
                            webinar_title={prismicGetText(prevWebinar.webinar_title)}
                            link_to_webinar={prevWebinar.link_to_webinar}
                            webinar_cover_photo={prevWebinar.webinar_cover_photo}
                        />
                    )
                })}
            </_Section>
        </Col>
    </Row>
)

export const mobileContent = (doc: any, heroRef: React.MutableRefObject<null>) => (
    <Row>
        <Col xs={12}>
            <_Section>
                <Hero
                    description="Radicle Webinar Series"
                    title={prismicGetText(doc.title_webinar)}
                    lead={prismicGetText(doc.subtitle_webinar)}
                    leftSideLgWidth={12}
                    headerFontSize={40}>
                    <Col sm={12}>
                        <Row middle="xs">
                            <Col lg={6} md={12} sm={12}>
                                <_description fontSize={14}>
                                    {
                                        buildModeratedByInfo({
                                            company: prismicGetText(doc.moderator_company),
                                            position: prismicGetText(doc.moderator_position),
                                            name: prismicGetText(doc.moderated_by),
                                        })
                                    }
                                </_description>
                                <_description fontSize={14} fontWeight="bold">
                                    {dateDecorator(doc.webinar_date)} at {prismicGetText(doc.webinar_hour)}
                                </_description>
                            </Col>

                            <_customAlignCol align="center" lg={6} md={12} sm={12}>
                                <_externalLinkButton ref={heroRef} href={doc.cta_link_to_registration.url}>
                                    {prismicGetText(doc.cta_label_webinar)}
                                </_externalLinkButton>
                            </_customAlignCol>
                        </Row>
                    </Col>
                </Hero>
            </_Section>

            <_SectionWithBorder marginTop={2.5}>
                <_description align="left" fontWeight="bold">Speakers</_description>
                {doc.speakers.map((speaker: any, index: number) => {
                    return (
                        <HorizontalSignature
                            key={index}
                            image={speaker.image_speaker.url}
                            name={prismicGetText(speaker.speaker_name)}
                            title={prismicGetText(speaker.speaker_position)}
                            company={prismicGetText(speaker.speaker_company_name)}
                            isMobile={true}
                        />
                    )
                })}

                <_description align="left" fontWeight="bold">Moderator</_description>
                <HorizontalSignature
                    image={doc.moderator_picture.url}
                    name={prismicGetText(doc.moderated_by)}
                    title={prismicGetText(doc.moderator_position)}
                    company={prismicGetText(doc.moderator_company)}
                    isMobile={true}
                />
            </_SectionWithBorder>

            <_Section>
                <_ContentWrapper>{prismicGetRichText(doc.description_webinar)}</_ContentWrapper>
            </_Section>

            <_Section>
                {doc.previous_webinars && doc.previous_webinars.length && <_description align="left" fontWeight="bold">Previous webinars</_description>}
                {doc.previous_webinars && doc.previous_webinars.length && doc.previous_webinars.map((prevWebinar: any, i: number) => {
                    return (
                        <PreviousWebinar
                            key={`${i}.${prevWebinar.webinar_title}`}
                            webinar_title={prismicGetText(prevWebinar.webinar_title)}
                            link_to_webinar={prevWebinar.link_to_webinar}
                            webinar_cover_photo={prevWebinar.webinar_cover_photo}
                        />
                    )
                })}
            </_Section>
        </Col>
    </Row>
)

interface WebinarPageContentProps {
    doc: any
    setIsFlashOpen(val: boolean): void
}

export const WebinarPageContent: React.FC<WebinarPageContentProps> = ({ doc, setIsFlashOpen }) => {
    const appState = React.useContext(AppStateContext)
    const heroRef = React.useRef(null)
    const [isReady, setIsReady] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setIsReady(true)
        }
        if (heroRef && heroRef.current) {
            const observer = new IntersectionObserver(
                entries => {
                    for (const { isIntersecting } of entries) {
                        setIsFlashOpen(!isIntersecting)
                    }
                },
                {
                    rootMargin: "-70px"
                }
            )

            observer.observe(heroRef.current!)
            return () => observer.disconnect()
        }
    })

    if (!isReady) return null
    return appState.isMobile ? mobileContent(doc, heroRef) : desktopContent(doc, heroRef)
}
