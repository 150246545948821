import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h1, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _externalLinkButton, _linkBlockButton } from "../shared/buttons/"
import { Row, Grid } from "react-flexbox-grid"
import styled from "styled-components"
import { _Section } from "../../components/shared"

const _FlashWrapper = styled.div`
    border: solid 1px #eeeeee;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--white);
    padding-top: 2em;
    padding-bottom: 2em;

    p {
        margin: 0;
        color: var(--white);
    }
`

const _MobileFlashWrapper = styled(_FlashWrapper)`
    padding-top: 0em;
    padding-bottom: 0em;
`

const _flashFormButtonWrapper = styled.div`
    display: inline-flex;
`

const _flashLead = styled.div`
    flex: 1;
`

const _flashContentTitle = styled.div`
    height: 24px;
    font-family: Nunito;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1d253c;
`

const _flashContentSubtitle = styled.div`
    height: 20px;
    font-family: Nunito;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1d253c;
`

const _flashContentDate = styled(_flashContentSubtitle)`
    font-weight: bold;
  }
`

interface ActionFlashProps {
    isOpen: boolean
    cta: {
        url: string
        label: string
    }
    content: any
}

export const ActionFlash: React.FC<ActionFlashProps> = ({ isOpen, cta, content }) => {
    const appState = React.useContext(AppStateContext)

    const desktopContent = (
        <_FlashWrapper>
            <Grid>
                <Row middle="xs">
                    <_flashLead>
                        <_flashContentTitle>{content.title}</_flashContentTitle>
                        <_flashContentSubtitle>{content.lead}</_flashContentSubtitle>
                        <_flashContentDate>
                            {content.date} at {content.hour}
                        </_flashContentDate>
                    </_flashLead>
                    <_flashFormButtonWrapper>
                        <_externalLinkButton href={cta.url}>{cta.label}</_externalLinkButton>
                    </_flashFormButtonWrapper>
                </Row>
            </Grid>
        </_FlashWrapper>
    )

    const mobileContent = (
        <_MobileFlashWrapper>
            <Grid>
                <Row middle="xs">
                    <_flashLead>
                        <_flashContentDate>{content.date}</_flashContentDate>
                        <_flashContentDate>{content.hour}</_flashContentDate>
                    </_flashLead>
                    <_flashFormButtonWrapper>
                        <_externalLinkButton marginTop={1} marginBottom={1} href={cta.url}>
                            {cta.label}
                        </_externalLinkButton>
                    </_flashFormButtonWrapper>
                </Row>
            </Grid>
        </_MobileFlashWrapper>
    )

    if (!isOpen) return null
    return appState.isMobile ? mobileContent : desktopContent
}
