import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { _processLink } from "../components/shared/links.styles"
import { PageWrapper } from "../utils/PageWrapper"
import { graphql } from "gatsby"
import { _h1, _h3, _description } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { prismicGetText, extractPrismicNode } from "../utils/prismicHelper"
import { _externalLinkButton } from "../components/shared/buttons/"
import { _customAlignCol } from "../components/shared/"
import { dateDecorator } from "../utils"
import { ActionFlash } from "../components/webinar/ActionFlash"
import { _SectionWithBorder } from "./webinar.styles"
import { WebinarPageContent } from "../components/webinar/WebinarPageContent"
import { truncate } from "../utils/responsiveHelper"

export const query = graphql`
    query WebinarQuery($uid: String) {
        prismic {
            ...webinarTemplateFragment
        }
    }
`

interface WebinarPageProps {
    data: {
        prismic: {
            allWebinars: {
                edges: any
            }
        }
    }
}

const WebinarPage: React.FC<WebinarPageProps> = ({ data }) => {
    const doc = extractPrismicNode(data, "allWebinars")
    if (!doc) return null

    const [isFlashOpen, setIsFlashOpen] = React.useState<boolean>(false)
    const webinarTitleTruncated = truncate(prismicGetText(doc.title_webinar), 40)

    const webinarImage =
        (doc.cover_photo_webinar && doc.cover_photo_webinar.url) ||
        (doc.speakers && doc.speakers[0].image_speaker && doc.speakers[0].image_speaker.url)

    return (
        <>
            <HeadComponent
                title={`${webinarTitleTruncated} | Radicle`}
                og_tags={{
                    image_url: webinarImage,
                    title: prismicGetText(doc.title_webinar),
                    description: prismicGetText(doc.subtitle_webinar),
                    type: "article"
                }}
            />

            <PageWrapper>
                <Layout>
                    <WebinarPageContent doc={doc} setIsFlashOpen={setIsFlashOpen} />
                    <ActionFlash
                        content={{
                            title: prismicGetText(doc.title_webinar),
                            lead: prismicGetText(doc.subtitle_webinar),
                            date: dateDecorator(doc.webinar_date),
                            hour: prismicGetText(doc.webinar_hour)
                        }}
                        isOpen={isFlashOpen}
                        cta={{
                            url: doc.cta_link_to_registration.url,
                            label: prismicGetText(doc.cta_label_webinar)
                        }}
                    />
                </Layout>
            </PageWrapper>
        </>
    )
}

export default WebinarPage
